import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import calculateSliderPos from '../../utilities/calculateSliderPos';

const calcGradientPerc = (min, val) => (min * 100) / val;

const RangeInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  order: 2;
  flex: 1;
  height: 30px;
  @media ${props => props.theme.mediaBreakpoints.tablet} {
    order: 1;
    flex-basis: 100%;
  }
`;

const thumbStyles = css`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  border: 0.5px solid ${props => props.theme.colours.slate};
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: 0.35s ease;

  &:hover {
    border-width: 2px;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  z-index: 4;
  outline: none;

  -webkit-appearance: none;
  background: transparent;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    ${thumbStyles}
  }
  &::-moz-range-thumb {
    ${thumbStyles}
  }
  &::-ms-thumb {
    ${thumbStyles}
  }

  &:hover {
    cursor: pointer;
  }
`;

export const StyledProgress = styled.progress`
  position: absolute;
  -webkit-appearance: none;
  appearance: none;
  z-index: 1;
  height: 15px;
  border-radius: 10px;
  color: ${props => props.theme.colours.nimbleRed};
  width: 99.5%;

  --progress-background-color: ${props => props.theme.colours.nimbleRed};

  &::-webkit-progress-value {
    border-radius: 10px;
    background: ${props => `linear-gradient(90deg, ${props.theme.colours.grey}
    ${calcGradientPerc(props.min, props.value)}%, ${
      props.theme.colours.nimbleRed
    } 
    ${calcGradientPerc(props.min, props.value)}%)`};
  }
  &::-webkit-progress-bar {
    border-radius: 10px;
    background-color: ${props => props.theme.colours.grey};
  }
  &::-moz-progress-bar {
    border-radius: 10px;
    background: ${props => `linear-gradient(90deg, ${props.theme.colours.grey} 
    ${calcGradientPerc(props.min, props.value)}%, ${
      props.theme.colours.nimbleRed
    } 
    ${calcGradientPerc(props.min, props.value)}%)`};
  }
`;

const StyledTooltip = styled.div`
  position: absolute;
  transform: translateX(-50%);
  max-width: 200px;
  padding: 0 0.5rem;
  height: 26px;
  line-height: 26px;
  color: white;
  text-align: center;
  opacity: 0.9;
  bottom: calc(0.75em + 0.375rem + 1px + 0.4rem + 16px);
  border-radius: 0.05rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  white-space: nowrap;
`;

const StyledTooltipLabel = styled.div`
  position: absolute;
  top: -5px;
  transform: translateX(-50%);
  max-width: 200px;
  padding: 2px 9px;
  color: white;
  text-align: center;
  color: ${props => props.theme.colours.slate};
  background-color: #ffffff;
  border: 1px solid ${props => props.theme.colours.slate};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 25%);
  border-radius: 5px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    padding: 1px 5px;
    font-size: 14px;
  }
`;

const StyledTooltipArrowInner = styled.div`
  position: absolute;
  top: -12px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ffffff;
`;

const StyledTooltipArrow = styled.div`
  position: absolute;
  bottom: -10px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid ${props => props.theme.colours.slate};

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    bottom: -8px;
  }
`;

const RangeInputMarks = ({
  min,
  max,
  value,
  tooltip,
  minLimit,
  formatter,
  ariaLabel,
  markId,
  marks,
  ...rest
}) => (
  <RangeInputContainer>
    <StyledInput
      autoComplete="off"
      min={min}
      max={max}
      value={value}
      aria-label={ariaLabel}
      list={markId}
      {...rest}
    />
    <datalist id={markId}>
      {marks.map(mark => (
        <option value={mark} />
      ))}
    </datalist>
    <StyledProgress
      min={0}
      max={100}
      value={((value - min) / (max - min)) * 100}
    />
    {tooltip && (
      <StyledTooltip
        style={{
          left: `calc(${calculateSliderPos(value, max, min)[0]}% + ${
            calculateSliderPos(value, max, min)[1]
          }px)`
        }}
      >
        <StyledTooltipLabel>{formatter(value)}</StyledTooltipLabel>
        <StyledTooltipArrow>
          <StyledTooltipArrowInner />
        </StyledTooltipArrow>
      </StyledTooltip>
    )}
  </RangeInputContainer>
);

RangeInputMarks.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  tooltip: PropTypes.bool,
  minLimit: PropTypes.number,
  formatter: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
  markId: PropTypes.string.isRequired,
  marks: PropTypes.arrayOf(PropTypes.number)
};

export default RangeInputMarks;
