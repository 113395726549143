import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import CalculatorOld from './components/calculator-old.component';
import Calculator from './components/calculator.component';
import ReviewBar from './components/reviewBar.component';
import TickGreen from '../../../static/images/tick-circle-green.svg';
import TickWhite from '../../../static/images/tick-circle-white.svg';
import { shouldInsertTooltip } from '../../hooks/tooltips/insertTooltip';
import infoIconWhite from '../../../static/images/info_outline_white.svg';
import infoIconGreen from '../../../static/images/info_outline_green.svg';
import { routes } from '../../shared/constants';

const HeroContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 1403px;
  margin: auto;
  padding: 30px 60px 0;

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    display: block;
    padding: 30px 30px 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    padding: 30px 40px 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 15px 30px 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    padding: 0 24px;
  }
`;

const HeroContent = styled.div`
  padding-top: 50px;
  padding-right: 30px;
  box-sizing: border-box;
  flex: 1;

  @media(max-width: 1600px){
    padding-top: 60px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.xl}) {
    padding-right: 10px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    margin: auto;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 20px;
    max-width: 550px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    padding-top: 0;
  }

  @media(max-width: ${props =>
    props.theme.breakpoints.max.sm}) and (max-height: 600px) {
    padding-bottom: 12px;
  }
  
  .nimble-tooltip .info-icon{
    background-image: url('${infoIconWhite}');

    @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
      background-image: url('${infoIconGreen}');
    }  
  }
`;

const HeroTitle = styled.h1`
  color: ${props => props.theme.colours.slate};
  font-family: ${props => props.theme.font.nimbleFont};
  font-size: 35px;
  line-height: 42px;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 0;
  text-transform: unset;

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    font-size: 32px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 0px;
  }

  @media (max-width: ${props =>
      props.theme.breakpoints.max.sm}) and (max-height: 600px) {
    font-size: 18px;
    margin-bottom: 0;
  }
`;

const HeroSubtitle = styled.p`
  color: ${props => props.theme.colours.slate};
  font-family: ${props => props.theme.font.nimbleFont};
  font-weight: 600;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0;
`;

const PointsContainer = styled.ul`
  list-style: none;
  margin-top: 21px;
  max-width: 475px;

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    display: none;
  }
`;

const MobilePointsContainer = styled.ul`
  list-style: none;
  margin-top: 20px;
  max-width: 475px;

  @media (min-width: ${props => props.theme.breakpoints.min.sm}) {
    display: none;
  }
`;

const Point = styled.li`
  position: relative;
  font-size: 14px;
  margin-bottom: 15px;
  padding-left: 4px;

  &::before {
    content: '';
    position: absolute;
    top: 3px;
    left: -2em;
    width: 20px;
    height: 20px;
    background-image: url('${TickGreen}');
    background-position: center;
    background-size: cover;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    margin-bottom: 7px;
  }  
`;

const Hero = styled.div`
  ${props =>
    props.background &&
    css`
    position: relative;

    @media(max-width: ${props.theme.breakpoints.max.lg}) {
      background-image: none;
    }

    @media(min-width: ${props.theme.breakpoints.min.lg}) {
      ${HeroContent} {
        h1, p, li {
          color: #ffffff;
        }
      }

      ${Point} {
        &::before {
          background-image: url('${TickWhite}');
        }
      }
    }
  `}

  ${props =>
    props.heroTextBottom &&
    css`
      background-position: center 37%;

      ${HeroContent} {
        align-self: flex-end;
      }
    `}

  ${props =>
    !props.heroTextBottom &&
    css`
      ${PointsContainer} {
        @media (max-width: 1300px) and (min-width: ${props.theme.breakpoints.min
            .lg}) {
          max-width: 320px;
        }
      }

      ${HeroContent} {
        @media (max-width: 1600px) and (min-width: ${props.theme.breakpoints.min
            .lg}) {
          padding-top: 30px;
        }
      }
    `}
`;

const HeroImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    position: relative;
    height: 400px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    height: 300px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    height: 175px;
  }

  @media (max-width: ${props =>
      props.theme.breakpoints.max.sm}) and (max-height: 650px) {
    display: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    margin-bottom: 10px;
  }
`;

const HeroImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('${props => props.background}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 1800px) {
    background-position: center 35%;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    ${props =>
      props.mobileBackground &&
      css`
      background-image: url('${props.mobileBackground}');
    `}
  }  
`;

const HeroGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(
    89.79deg,
    rgba(169, 169, 169, 0.69) 0.18%,
    rgba(255, 255, 255, 0) 56.27%
  );
  mix-blend-mode: multiply;

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    display: none;
  }
`;

const HeroCalculator = styled.div`
  position: relative;
  z-index: 2;
  flex: 0 0 520px;
  margin-bottom: -130px;

  @media (max-width: 1300px) {
    flex: 0 0 450px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    flex: 0 0 375px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -120px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    margin-bottom: 0;
  }
`;

const getPricingData = async () => {
  const PricingApiEndpoint = `${routes.global.urls.fsaNimble.fgfPricingUrl}/nimble/allPrices`;
  try {
    const response = await fetch(PricingApiEndpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      cache: 'no-store'
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Error fetching pricing data');
    }
  } catch (error) {
    throw new Error('Error fetching pricing data');
  }
};

const NimbleHero = ({ data, heroTextBottom }) => {
  const {
    title,
    subtitle,
    points,
    backgroundImage,
    mobileBackgroundImage,
    ratingsTitle,
    ratings,
    fgfCalculator,
    calculator
  } = data;

  const [pricingData, setPricingData] = useState(null);

  useEffect(async () => {
    const pricingData = await getPricingData();

    setPricingData(pricingData);
  }, []);

  return (
    <Hero heroTextBottom={heroTextBottom} background={!!backgroundImage}>
      {backgroundImage && (
        <HeroImageWrapper>
          <HeroImage
            background={backgroundImage.file.url}
            mobileBackground={
              mobileBackgroundImage ? mobileBackgroundImage.file.url : null
            }
          />
          <HeroGradient />
        </HeroImageWrapper>
      )}

      <HeroContainer>
        <HeroContent>
          {title && <HeroTitle dangerouslySetInnerHTML={{ __html: title }} />}
          {subtitle && <HeroSubtitle>{subtitle}</HeroSubtitle>}
          <PointsContainer>
            {points &&
              points.map(({ heading }) => {
                return (
                  <Point key={heading}>{shouldInsertTooltip(heading)}</Point>
                );
              })}
          </PointsContainer>
        </HeroContent>
        <HeroCalculator>
          {calculator && <CalculatorOld calculatorData={calculator} />}
          {pricingData && fgfCalculator && (
              <Calculator
                calculatorData={fgfCalculator}
                pricingData={pricingData}
              />          
          )}
        </HeroCalculator>
        <MobilePointsContainer>
          {points &&
            points.map(({ heading }) => {
              return (
                <Point key={heading}>{shouldInsertTooltip(heading)}</Point>
              );
            })}
        </MobilePointsContainer>
      </HeroContainer>
      <ReviewBar reviewData={{ ratingsTitle, ratings }} />
    </Hero>
  );
};
NimbleHero.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    points: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    backgroundImage: PropTypes.shape({
      file: PropTypes.objectOf(PropTypes.string)
    }),
    mobileBackgroundImage: PropTypes.shape({
      file: PropTypes.objectOf(PropTypes.string)
    }),
    ratingsTitle: PropTypes.string,
    ratings: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        logo: PropTypes.shape({
          file: PropTypes.objectOf(PropTypes.string)
        }),
        ratingNumber: PropTypes.number
      })
    ),
    calculator: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])
    )
  }),
  heroTextBottom: PropTypes.bool
};
export default NimbleHero;
