/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from '../../elements/select/pureSelect.component';
import RangeSliderMarks from '../../elements/calculatorRange/rangeSliderMarks';
import { getClosestMark } from '../../utilities/numberHelpers';
import { formatCurrency, formatMoney } from '../../utilities/formatters';
import { shouldInsertTooltip } from '../../hooks/tooltips/insertTooltip';

const StyledSectionContainer = styled.div`
  padding: 64px 0px;

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 40px 0px;
  }
`;

const StyledContainer = styled.div`
  padding: 0px 60px;
  max-width: 1403px;
  margin: auto;

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    padding: 0px 40px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 0px 24px;
  }
`;

const StyledHeading = styled.h2`
  color: ${props => props.theme.colours.black};
  font-family: ${props => props.theme.font.nimbleFont};
  font-style: normal;
  font-size: 29px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 7px;

  @media (max-width: 850px) {
    margin-bottom: 24px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const CalculatorForm = styled.form`
  @media (min-width: 851px) {
    display: flex;
  }
`;

const FieldsContainer = styled.div`
  flex: 0 0 50%;
  padding: 40px 80px 40px 0;

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    padding: 24px 64px 24px 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    padding: 24px 44px 24px 0;
  }

  @media (max-width: 850px) {
    padding: 0 0 16px;
  }
`;

const FieldContainer = styled.div`
  margin-bottom: 24px;
`;

const SelectContainer = styled.div`
  margin-top: 12px;
`;

const StyledLabel = styled.label`
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const LabelValues = styled.span`
  color: ${props => props.theme.colours.darkGreen};
  margin-left: 8px;
`;

const StyledDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  margin: -4px 0 8px;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    color: #828282;
  }
`;

const RepaymentDetailsContainer = styled.div`
  flex: 0 0 50%;
  align-self: flex-start;
  padding: 64px;
  background-color: ${props => props.theme.colours.greyBack};

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    padding: 40px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    padding: 32px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 0%;
    background-color: transparent;
  }
`;

const DetailsContainer = styled.div`
  width: 100%;
`;

const DetailsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const DetailsTitle = styled.p`
  max-width: 50%;
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const DetailsValue = styled.p`
  display: flex;
  flex: 0 0 auto;
  color: ${props => props.theme.colours.nimbleRed};
  text-align: right;
  font-weight: 600;
  padding-left: 30px;
  margin: 0;
  align-items: baseline;
  font-size: 35px;
  line-height: 40px;

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    font-size: 30px;
    line-height: 35px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    font-size: 20px;
    line-height: 24px;
    padding-left: 16px;
  }
`;

const DetailsValueSmall = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-left: 2px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin: 24px auto 0;
`;

const StyledButton = styled.button`
  width: 100%;
  border-radius: 4px;
  background-color: ${props => props.theme.colours.green};
  border: none;
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
  transition: 0.35s ease;
  text-transform: none;
  cursor: pointer;
  font-family: ${props => props.theme.font.nimbleFont};
  font-weight: 600;
  padding: 12px 30px;

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${props => props.theme.colours.nimbleRed};
      color: #ffffff;
    }
  }
`;
const DisclaimerText = styled.p`
  font-size: 12px;
  margin-bottom: 0;
`;

const CompareContainer = styled.div`
  margin-top: 30px;
  text-align: center;

  @media (min-width: 851px) {
    display: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 24px;
    background-color: ${props => props.theme.colours.greyBack};
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    padding: 20px 16px;
  }
`;

const CompareTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #4f4f4f;
  margin: 0 0 24px;

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    margin: 0 0 12px;
  }
`;

const CompareLink = styled.a`
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  border: 2px solid ${props => props.theme.colours.slate};
  font-size: 18px;
  line-height: 24px;
  transition: 0.35s ease;
  text-transform: none;
  cursor: pointer;
  font-family: ${props => props.theme.font.nimbleBoldFont};
  font-weight: 400;
  padding: 12px 30px;
  box-sizing: border-box;

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    display: inline-block;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    font-size: 16px;
    line-height: 24px;
    padding: 12px;
  }
`;

const Calculator = ({ calculatorData, pricingData }) => {
  const {
    title,
    hashId,
    loanAmountLabel,
    loanAmountDescription,
    loanTermLabel,
    loanTermDescription,
    repaymentFrequencyTitle,
    repaymentFrequencyDescription,
    defaultLoanAmount,
    defaultLoanTerm,
    minLoanAmount,
    maxLoanAmount,
    repaymentTitle,
    interestRateTitle,
    comparisonRateTitle,
    applyLink
  } = calculatorData;

  /** All unique loan amounts within range (if max or min set) */
  const allLoanAmounts = pricingData
    .map(data => data.loanAmount)
    .reduce((p, v) => {
      if (
        p.indexOf(v) === -1 &&
        (minLoanAmount ? v >= minLoanAmount : true) &&
        (maxLoanAmount ? v <= maxLoanAmount : true)
      )
        p.push(v);
      return p;
    }, [])
    .sort((a, b) => a - b);
  const amountMin = allLoanAmounts[0];
  const amountMax = allLoanAmounts[allLoanAmounts.length - 1];

  let initLoanAmount = defaultLoanAmount;

  if (defaultLoanAmount < amountMin || defaultLoanAmount > amountMax) {
    initLoanAmount = amountMin;
  }

  // Make sure loan amount is a mark
  initLoanAmount = getClosestMark(allLoanAmounts, initLoanAmount);

  const [selectedLoanAmount, setSelectedLoanAmount] = useState(
    Number(initLoanAmount) ?? 3000
  );

  /** All term values for selected loan amount */
  const filteredTerms = filterTerms(selectedLoanAmount);
  const termMin = filteredTerms[0];
  const termMax = filteredTerms[filteredTerms.length - 1];

  function filterTerms(loanAmount) {
    return pricingData
      .filter(data => data.loanAmount === loanAmount)
      .map(data => data.loanLength)
      .sort((a, b) => a - b);
  }

  let initLoanTerm = defaultLoanTerm;

  if (defaultLoanTerm < termMin || defaultLoanTerm > termMax) {
    initLoanTerm = termMin;
  }

  // Make sure loan term is a mark
  initLoanTerm = getClosestMark(filteredTerms, initLoanTerm);

  const [selectedTerm, setSelectedTerm] = useState(Number(initLoanTerm) ?? 12);
  const [selectedRepaymentFrequency, setSelectedRepaymentFrequency] = useState(
    'fortnight'
  );

  /** Data object to match selectedLoanAmount and selectedTerm values */
  const selectedData = pricingData.find(
    data =>
      data.loanAmount === selectedLoanAmount && data.loanLength === selectedTerm
  );

  function changeSliders(loanAmount) {
    const terms = filterTerms(loanAmount);
    const termMin = terms[0];
    const termMax = terms[terms.length - 1];
    if (selectedTerm < termMin || selectedTerm > termMax) {
      selectedTerm - termMin < termMax - selectedTerm
        ? setSelectedTerm(termMin)
        : setSelectedTerm(termMax);
    }

    //set loan amount after term change so no juttering
    setSelectedLoanAmount(loanAmount);
  }

  const handleChangeFrequency = event => {
    setSelectedRepaymentFrequency(event.target.value);
  };

  return (
    <StyledSectionContainer id={hashId}>
      <StyledContainer>
        <StyledHeading>{title}</StyledHeading>
        <CalculatorForm
          action={applyLink && applyLink.linkUrl}
          method="get"
        >
          <FieldsContainer>
            <FieldContainer>
              <StyledLabel>
                {loanAmountLabel}
                <LabelValues>{formatCurrency(selectedLoanAmount)}</LabelValues>
              </StyledLabel>
              {loanAmountDescription && (
                <StyledDescription>
                  {shouldInsertTooltip(loanAmountDescription)}
                </StyledDescription>
              )}
              <RangeSliderMarks
                onChangeCallback={changeSliders}
                inputName="loanAmount"
                ariaLabel="Loan amount"
                value={selectedLoanAmount}
                markId="heroLoanAmount"
                marks={allLoanAmounts}
                min={amountMin}
                max={amountMax}
                formatter={formatCurrency}
                hasLabels
                labelsFormatter={formatCurrency}
              />
            </FieldContainer>
            <FieldContainer>
              <StyledLabel>
                {loanTermLabel}
                <LabelValues>{`${selectedTerm} months`}</LabelValues>
              </StyledLabel>
              {loanTermDescription && (
                <StyledDescription>
                  {shouldInsertTooltip(loanTermDescription)}
                </StyledDescription>
              )}
              <RangeSliderMarks
                onChangeCallback={setSelectedTerm}
                inputName="loanTerm"
                ariaLabel="Loan term"
                value={selectedTerm}
                markId="heroLoanTerm"
                marks={filteredTerms}
                min={termMin}
                max={termMax}
                formatter={e => `${e} months`}
                hasLabels
                labelsFormatter={e => `${e} months`}
              />
            </FieldContainer>
            <FieldContainer>
              <StyledLabel>
                {shouldInsertTooltip(repaymentFrequencyTitle)}
              </StyledLabel>
              <StyledDescription>
                {shouldInsertTooltip(repaymentFrequencyDescription)}
              </StyledDescription>
              <SelectContainer>
                <Select
                  onChange={handleChangeFrequency}
                  value={selectedRepaymentFrequency}
                >
                  <option value="week">Weekly</option>
                  <option value="fortnight">Fortnightly</option>
                  <option value="month">Monthly</option>
                </Select>
              </SelectContainer>
            </FieldContainer>
          </FieldsContainer>
          <RepaymentDetailsContainer>
            <DetailsContainer>
              <DetailsItem>
                <DetailsTitle>
                  {shouldInsertTooltip(repaymentTitle)}
                </DetailsTitle>
                <DetailsValue>
                  {selectedRepaymentFrequency === 'week' &&
                    formatMoney(selectedData.weeklyRepayment)}
                  {selectedRepaymentFrequency === 'fortnight' &&
                    formatMoney(selectedData.weeklyRepayment * 2)}
                  {selectedRepaymentFrequency === 'month' &&
                    formatMoney(selectedData.monthlyRepayment)}
                  <DetailsValueSmall>{`/${selectedRepaymentFrequency}`}</DetailsValueSmall>
                </DetailsValue>
              </DetailsItem>
              {interestRateTitle && (
                <DetailsItem>
                  <DetailsTitle>
                    {shouldInsertTooltip(interestRateTitle)}
                  </DetailsTitle>
                  <DetailsValue>
                    {selectedData.interestRate}
                    <DetailsValueSmall>% p.a.</DetailsValueSmall>
                  </DetailsValue>
                </DetailsItem>
              )}
              {comparisonRateTitle && (
                <DetailsItem>
                  <DetailsTitle>
                    {shouldInsertTooltip(comparisonRateTitle)}
                  </DetailsTitle>
                  <DetailsValue>
                    {selectedData.comparisonRate}
                    <DetailsValueSmall>% p.a.</DetailsValueSmall>
                  </DetailsValue>
                </DetailsItem>
              )}
            </DetailsContainer>
            {applyLink && (
              <ButtonContainer>
                <StyledButton type="submit">{applyLink.linkText}</StyledButton>
              </ButtonContainer>
            )}
            <DisclaimerText>{selectedData.disclaimer}</DisclaimerText>
          </RepaymentDetailsContainer>
        </CalculatorForm>
        <CompareContainer>
          <CompareTitle>
            Looking for other products that might suit you better?
          </CompareTitle>
          <CompareLink href="/loans">Let’s compare products</CompareLink>
        </CompareContainer>
      </StyledContainer>
    </StyledSectionContainer>
  );
};

Calculator.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    hashId: PropTypes.string,
    loanAmountLabel: PropTypes.string,
    loanAmountDescription: PropTypes.string,
    loanTermLabel: PropTypes.string,
    loanTermDescription: PropTypes.string,
    repaymentFrequencyTitle: PropTypes.string,
    repaymentFrequencyDescription: PropTypes.string,
    defaultLoanAmount: PropTypes.number,
    defaultLoanTerm: PropTypes.number,
    minLoanAmount: PropTypes.number,
    maxLoanAmount: PropTypes.number,
    repaymentTitle: PropTypes.string,
    interestRateTitle: PropTypes.string,
    comparisonRateTitle: PropTypes.string,
    applyLink: PropTypes.shape({
      linkUrl: PropTypes.string,
      linkText: PropTypes.string
    })
  })
};

export default Calculator;
