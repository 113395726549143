import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { getClosestMark } from '../../utilities/numberHelpers';
import RangeInputMarks from './rangeInputMarks';

const RangeTextMinimum = styled.span`
  color: #4f4f4f;
  padding: 4px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  order: 1;
  @media ${props => props.theme.mediaBreakpoints.tablet} {
    order: 2;
  }
`;

const RangeTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const RangeTextMaximum = styled.span`
  color: #4f4f4f;
  padding: 4px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  order: 3;
  @media ${props => props.theme.mediaBreakpoints.tablet} {
    order: 3;
  }
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;

  ${props => props.hasButtons && css`
    padding: 0px 45px;
  `};

  ${props => props.hasToolitip && css`
    margin: 45px auto 20px;
  `};
`;

const UpdateButton = styled.button`
  position: absolute;
  top: 0px;
  font-family: ${props => props.theme.font.nimbleFont};
  color: ${props => props.theme.colours.slate};
  font-weight: 400;
  background-color: #ffffff;
  padding: 3px 8px 0px;
  font-size: 25px;
  line-height: 26px;
  border-radius: 5px;
  border: 0.5px solid ${props => props.theme.colours.slate};
  transition: 0.35s ease;
  cursor: pointer;

  &:focus{
    background-color: #ffffff;
  }

  @media(hover: hover){
    &:hover{
      color: #ffffff;
      background-color: ${props => props.theme.colours.darkGreen};
    }
  }

  ${props => props.addAmount && css`
    right: 0px;
  `};
  ${props => props.removeAmount && css`
    left: 0px;
  `};
`;

const RangeSliderMarks = ({
  onChangeCallback,
  inputName,
  ariaLabel,
  value,
  markId,
  marks,
  hasButtons,
  min,
  max,
  formatter,
  hasLabels,
  labelsFormatter,
  hasToolitip
}) => {
  const [sliderAmount, setSliderAmount] = useState(value);

  useEffect(() => {
    setSliderAmount(value);
  }, [value]);

  const handleChange = e => {
    const change = getClosestMark(marks, e.target.value);
    setSliderAmount(change);
    onChangeCallback(change);
  };
  const updateAmount = (updateType) => {
    const amountIndex = marks.indexOf(sliderAmount);
    if (updateType === 'add' && sliderAmount !== max) {
      if (amountIndex !== -1 && amountIndex !== marks.length) {
        setSliderAmount(marks[amountIndex + 1]);
        onChangeCallback(marks[amountIndex + 1]);
      }
    } else if (updateType === 'remove' && sliderAmount !== min) {
      if (amountIndex > 0) {
        setSliderAmount(marks[amountIndex - 1]);
        onChangeCallback(marks[amountIndex - 1]);
      }
    }
  };

  return (
    <SliderContainer hasButtons={hasButtons} hasToolitip={hasToolitip}>
      {hasButtons && <UpdateButton removeAmount aria-label="Remove Amount" type="button" onClick={() => updateAmount('remove')}>-</UpdateButton>}
      <RangeInputMarks
        name={inputName}
        ariaLabel={ariaLabel}
        type="range"
        min={min}
        max={max}
        value={sliderAmount}
        onChange={handleChange}
        markId={markId}
        marks={marks}
        formatter={formatter}
        tooltip={hasToolitip}
      />
      {hasButtons && <UpdateButton addAmount aria-label="Add Amount" type="button" onClick={() => updateAmount('add')}>+</UpdateButton>}
      {hasLabels && (
        <RangeTextContainer>
          <RangeTextMinimum>
            {labelsFormatter(min)}
          </RangeTextMinimum>
          <RangeTextMaximum>
            {labelsFormatter(max)}
          </RangeTextMaximum>
        </RangeTextContainer>
      )}
    </SliderContainer>
  );
};

RangeSliderMarks.propTypes = {
  onChangeCallback: PropTypes.func,
  inputName: PropTypes.string,
  ariaLabel: PropTypes.string,
  value: PropTypes.number.isRequired,
  hasButtons: PropTypes.bool,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  formatter: PropTypes.func,
  hasLabels: PropTypes.bool,
  labelsFormatter: PropTypes.func,
  hasToolitip: PropTypes.bool,
  markId: PropTypes.string.isRequired,
  marks: PropTypes.arrayOf(PropTypes.number)
};
export default RangeSliderMarks;
