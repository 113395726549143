import styled from 'styled-components';
import { theme } from '../../theme/theme-vars';

const BasicComponentStyles = styled.div`
  .component-basic {
    display: flex;

    h2,
    h3,
    h4 {
      font-style: normal;
    }

    strong, b {
      font-family: 'SharpGroteskSmBold';
    }

    a{
      color: ${theme.colours.red};
      transition: 0.35s ease;

      &:hover{
        color: #a32926;
      }
    }

    > .margin-left {
      flex-grow: 0;
      position: relative;

      .margin-image {
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }

    > .margin-right {
      flex-grow: 0;
      display: none;
    }

    > .component-basic-content {
      max-width: 900px;
      margin: 0 auto;
      padding: 40px;

      @media (max-width: ${theme.components.phoneWidthMax}) {
        padding: 40px 30px;
      }
    }

    &.component-basic-wide{
      > .component-basic-content {
        max-width: 1200px;

        img {
          max-width: 100%;
        }
      }
    }

    &.component-basic-timeline{
      > .component-basic-content {
        max-width: 1700px;
        padding: 60px 15px;

        @media (max-width: ${theme.breakpoints.max.md}) {
          padding: 20px 15px;
        }

        img {
          max-width: 100%;
        }
      }
    }

    &.has-margin-image {
      > .margin-left {
        flex-grow: 1;
        background-color: ${theme.colours.orange};
      }

      > .margin-right {
        display: block;
      }

      > .component-basic-content {
        width: 65%;
      }

      @media (max-width: ${theme.components.phoneWidthMax}) {
        flex-direction: column;

        > .margin-left {
          height: 300px;
        }

        > .component-basic-content {
          width: 100%;
        }
      }
    }
    blockquote {
      display: none;
    }

    .component-basic-content {
      padding-top: 30px;
      overflow: auto;
      .floated-image {
        text-align: center;
        margin-top: 5px;
        margin-bottom: 20px;

        &.floated-image-above {
          float: none;
          margin-left: auto;
          margin-right: auto;
        }

        &.floated-image-Left {
          float: left;
          margin-right: 20px;
        }

        &.floated-image-Right {
          float: right;
          margin-left: 20px;
        }

        &.floated-image-undefined{
          max-width: 100%;
        }

        &.floated-image-Left,
        &.floated-image-Right {
          max-width: 50%;

          @media (max-width: ${theme.components.phoneWidthMax}) {
            max-width: 100%;
            width: 100%;
            float: none;
            margin-left: auto;
            margin-right: auto;
          }
        }

        img {
          max-width: 100%;
        }
      }
      div.component-text {
        &:empty {
          display: none;
        }
      }
    }
  }
  .readmore-wrapper {
    padding: 0px 25px 30px;
    max-width: 900px;

    h1 {
      font-size: 52px !important;
    }
  }
  &.Padding-Top-None {
    padding-top: 0;

    .component-basic-content {
      padding-top: 10px;
    }
  }

  &.Padding-Bottom-None {
    padding-bottom: 0;

    .component-basic-content {
      padding-bottom: 0;

      .component-text {
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .cf-slate-paragraph {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.9;
    margin: 20px 0;
  }

  /* Read more toggleable component */
  .read-more {
    &--closed {
      display: none;
    }

    &--open {
      display: block;
    }

    .readmore-wrapper {
      margin-left: auto;
      margin-right: auto;
    }
  }

  table {
    border: none;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

    tbody {
      tr {
        border-left: 1px solid ${theme.colours.grey};
        border-right: 1px solid ${theme.colours.grey};
        border-bottom: 1px solid ${theme.colours.grey};

        p,
        td {
          font-size: 13px;
          line-height: 1.6;
          margin-top: 0;
          margin-bottom: 10px;

          @media (max-width: ${theme.components.desktopWidthMin}) {
            min-width: 150px;
          }
        }
      }
    }

    tr {
      &:nth-child(2n) {
        background-color: ${theme.colours.grey};
      }

      th,
      td {
        border: none;
        text-align: left !important;
        padding: 20px 15px;

        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
export default BasicComponentStyles;
