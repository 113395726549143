/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { routes } from '../../shared/constants';
import Calculator from './calculator';

const getPricingData = async () => {
  const PricingApiEndpoint = `${routes.global.urls.fsaNimble.fgfPricingUrl}/nimble/allPrices`;
  try {
    const response = await fetch(PricingApiEndpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      cache: 'no-store'
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Error fetching pricing data');
    }
  } catch (error) {
    throw new Error('Error fetching pricing data');
  }
};

const FGFCalculator = ({ data }) => {
  const [pricingData, setPricingData] = useState(null);

  useEffect(async () => {
    const pricingData = await getPricingData();

    setPricingData(pricingData);
  }, []);

  return (
    <>
      {pricingData && (
        <Calculator calculatorData={data} pricingData={pricingData} />
      )}
    </>
  );
};

export default FGFCalculator;
