/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RangeSliderMarks from '../../../elements/calculatorRange/rangeSliderMarks';
import { getClosestMark } from '../../../utilities/numberHelpers';
import { formatCurrency, formatMoney } from '../../../utilities/formatters';
import { shouldInsertTooltip } from '../../../hooks/tooltips/insertTooltip';

const CalculatorForm = styled.form`
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 10px;
  box-sizing: border-box;

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    padding: 40px 40px 24px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 0;
    box-shadow: none;
  }
`;

const Field = styled.div`
  margin-bottom: 30px;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    margin-bottom: 25px;
  }

  @media (max-width: ${props =>
      props.theme.breakpoints.max.sm}) and (max-height: 820px) {
    margin-bottom: 20px;
  }
`;

const StyledLabel = styled.label`
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
`;

const StyledDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  margin: -4px 0 8px;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    color: #828282;
  }
`;

const RepaymentDetailsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DetailsContainer = styled.div`
  display: flex;
  font-weight: 600;
`;

const DetailsItem = styled.div`
  margin-right: 25px;
  color: ${props => props.theme.colours.nimbleRed};

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 1300px) {
    text-align: center;
  }
`;

const ReplaymentAmountText = styled.span`
  display: inline-block;
  margin-right: 2px;
  font-size: 30px;
  line-height: 24px;

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 24px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    font-size: 21px;
    margin-right: 1px;
  }

  @media (max-width: 320px) {
    font-size: 18px;
  }
`;

const RepaymentFrequencyText = styled.span`
  display: inline-block;
  font-size: 14px;

  @media (max-width: 1300px) {
    font-size: 12px;
  }

  @media (max-width: 300px) {
    display: block;
  }
`;

const RepaymentDisclaimer = styled.p`
  color: ${props => props.theme.colours.neutralText};
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;
  min-height: 56px;

  @media (max-width: 1300px) {
    min-height: 84px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    min-height: 56px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    order: 10;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    min-height: unset;
    text-align: left;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin: 24px auto 0;
`;

const StyledButton = styled.button`
  width: 100%;
  border-radius: 4px;
  background-color: ${props => props.theme.colours.green};
  border: none;
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
  transition: 0.35s ease;
  text-transform: none;
  cursor: pointer;
  font-family: ${props => props.theme.font.nimbleFont};
  font-weight: 600;
  padding: 12px 30px;
  box-sizing: border-box;

  @media (hover: hover) {
    &:hover {
      background-color: ${props => props.theme.colours.nimbleRed};
      color: #ffffff;
    }
  }
`;

const Calculator = ({ calculatorData, pricingData }) => {
  const {
    loanAmountLabel,
    loanAmountDescription,
    loanTermLabel,
    loanTermDescription,
    defaultLoanAmount,
    defaultLoanTerm,
    minLoanAmount,
    maxLoanAmount,
    applyLink
  } = calculatorData;

  /** All unique loan amounts within range (if max or min set) */
  const allLoanAmounts = pricingData
    .map(data => data.loanAmount)
    .reduce((p, v) => {
      if (
        p.indexOf(v) === -1 &&
        (minLoanAmount ? v >= minLoanAmount : true) &&
        (maxLoanAmount ? v <= maxLoanAmount : true)
      )
        p.push(v);
      return p;
    }, [])
    .sort((a, b) => a - b);
  const amountMin = allLoanAmounts[0];
  const amountMax = allLoanAmounts[allLoanAmounts.length - 1];

  let initLoanAmount = defaultLoanAmount;

  if (defaultLoanAmount < amountMin || defaultLoanAmount > amountMax) {
    initLoanAmount = amountMin;
  }

  // Make sure loan amount is a mark
  initLoanAmount = getClosestMark(allLoanAmounts, initLoanAmount);

  const [selectedLoanAmount, setSelectedLoanAmount] = useState(
    Number(initLoanAmount) ?? 3000
  );

  /** All term values for selected loan amount */
  const filteredTerms = filterTerms(selectedLoanAmount);
  const termMin = filteredTerms[0];
  const termMax = filteredTerms[filteredTerms.length - 1];

  function filterTerms(loanAmount) {
    return pricingData
      .filter(data => data.loanAmount === loanAmount)
      .map(data => data.loanLength)
      .sort((a, b) => a - b);
  }

  let initLoanTerm = defaultLoanTerm;

  if (defaultLoanTerm < termMin || defaultLoanTerm > termMax) {
    initLoanTerm = termMin;
  }

  // Make sure loan term is a mark
  initLoanTerm = getClosestMark(filteredTerms, initLoanTerm);

  const [selectedTerm, setSelectedTerm] = useState(Number(initLoanTerm) ?? 12);

  /** Data object to match selectedLoanAmount and selectedTerm values */
  const selectedData = pricingData.find(
    data =>
      data.loanAmount === selectedLoanAmount && data.loanLength === selectedTerm
  );

  function changeSliders(loanAmount) {
    const terms = filterTerms(loanAmount);
    const termMin = terms[0];
    const termMax = terms[terms.length - 1];
    if (selectedTerm < termMin || selectedTerm > termMax) {
      selectedTerm - termMin < termMax - selectedTerm
        ? setSelectedTerm(termMin)
        : setSelectedTerm(termMax);
    }

    //set loan amount after term change so no juttering
    setSelectedLoanAmount(loanAmount);
  }

  return (
    <>
      {allLoanAmounts && filteredTerms && (
        <CalculatorForm action={applyLink && applyLink.linkUrl} method="get">
          <Field>
            <StyledLabel>{loanAmountLabel}</StyledLabel>
            {loanAmountDescription && (
              <StyledDescription>
                {shouldInsertTooltip(loanAmountDescription)}
              </StyledDescription>
            )}
            <RangeSliderMarks
              onChangeCallback={changeSliders}
              inputName="loanAmount"
              ariaLabel="Loan amount"
              value={selectedLoanAmount}
              markId="heroLoanAmount"
              marks={allLoanAmounts}
              hasButtons
              min={amountMin}
              max={amountMax}
              formatter={formatCurrency}
              hasToolitip
            />
          </Field>
          <Field>
            <StyledLabel>{loanTermLabel}</StyledLabel>
            {loanTermDescription && (
              <StyledDescription>
                {shouldInsertTooltip(loanTermDescription)}
              </StyledDescription>
            )}
            <RangeSliderMarks
              onChangeCallback={setSelectedTerm}
              inputName="loanTerm"
              ariaLabel="Loan term"
              value={selectedTerm}
              markId="heroLoanTerm"
              marks={filteredTerms}
              hasButtons
              min={termMin}
              max={termMax}
              formatter={e => `${e} months`}
              hasToolitip
            />
          </Field>
          <RepaymentDetailsContainer>
            {selectedData && (
              <>
                <RepaymentDisclaimer>
                  {selectedData.disclaimer}
                </RepaymentDisclaimer>
                <DetailsContainer>
                  <DetailsItem>
                    <ReplaymentAmountText>
                      {formatMoney(selectedData.weeklyRepayment)}
                    </ReplaymentAmountText>
                    <RepaymentFrequencyText>/week</RepaymentFrequencyText>
                  </DetailsItem>
                  <DetailsItem>
                    <ReplaymentAmountText>
                      {formatMoney(selectedData.weeklyRepayment * 2)}
                    </ReplaymentAmountText>
                    <RepaymentFrequencyText>/fortnight</RepaymentFrequencyText>
                  </DetailsItem>
                </DetailsContainer>
              </>
            )}
            {applyLink && (
              <ButtonContainer>
                <StyledButton type="submit">{applyLink.linkText}</StyledButton>
              </ButtonContainer>
            )}
          </RepaymentDetailsContainer>
        </CalculatorForm>
      )}
    </>
  );
};

Calculator.propTypes = {
  calculatorData: PropTypes.shape({
    title: PropTypes.string,
    loanAmountLabel: PropTypes.string,
    loanAmountDescription: PropTypes.string,
    loanTermLabel: PropTypes.string,
    loanTermDescription: PropTypes.string,
    defaultLoanAmount: PropTypes.number,
    defaultLoanTerm: PropTypes.number,
    minLoanAmount: PropTypes.number,
    maxLoanAmount: PropTypes.number,
    applyLink: PropTypes.shape({
      linkUrl: PropTypes.string,
      linkText: PropTypes.string
    })
  })
};
export default Calculator;
